<script>
import Store from './Store'
import PUDOModal from '@/components/Modals/PUDO.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'PUDO',
  extends: Store,
  data() {
    return {
      icon: 'bxs-store',
      namespace: 'pudo',
      modal: PUDOModal,
    }
  },
  computed: {
    ...mapState('pudo',{
      locationId: 'locationId',
      locationName: 'locationName',
    }),
  },
  methods:  {
    ...mapActions('pudo',{
      setLocation: 'setLocation',
      unsetLocation: 'unsetLocation',
    }),
  }
}
</script>