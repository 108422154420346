<template>
  <b-modal 
    ref="modal"
    body-class="p-0"
  >
    <template #modal-title>
      {{$t('camera.title')}}
    </template>

    <b-overlay
      :show="loading"
      rounded
      spinner-type="grow"
      spinner-variant="primary"
    >

      <video v-show="!imageTaken" ref="camera" :width="498" :height="373.5" autoplay></video>
      <canvas v-show="imageTaken" ref="canvas" :width="498" :height="373.5"></canvas>

    </b-overlay>

    <template #modal-footer>
      <b-button @click="close(null)">{{$t('common.cancel')}}</b-button>
      <b-button v-if="!imageTaken" @click="snap()" variant="info" ref="snap">{{$t('camera.snap')}}</b-button>
      <b-button v-if="imageTaken" @click="reset()" variant="info">{{$t('camera.retake')}}</b-button>
      <b-button v-if="imageTaken" @click="submit()" variant="primary" ref="submit">{{$t('camera.submit')}}</b-button>
    </template>
  </b-modal>
</template>

<script>
import Modal from "@/components/Modal"

export default {
  name: 'Camera',
  extends: Modal,
  components: {},
  props: {
  },
  mounted() {
    this.initCamera()
  },
  beforeDestroy() {
    this.stopCameraStream()
  },
  data() {
    return {
      loading: true,
      imageTaken: null,
    }
  },
  methods: {
    async initCamera() {
      const constraints = (window.constraints = {
        audio: false,
        video: {
          width: { ideal: 960 },
          height: { ideal: 720 },
          facingMode: 'environment',
        }
      })

      const stream = await navigator.mediaDevices.getUserMedia(constraints)
      this.$refs.camera.srcObject = stream;
      this.loading = false
      this.$refs.snap.focus()
    },
    stopCameraStream() {
      this.$refs.camera.srcObject.getTracks()
        .forEach(track => track.stop())
    },
    snap() {
      this.$refs.canvas.getContext('2d')
        .drawImage(this.$refs.camera, 0, 0, 498, 373.5)
      this.imageTaken = true
      setTimeout(() => this.$refs.submit.focus(),100)
    },
    reset() {
      this.imageTaken = false
    },
    submit() {
      this.close(this.$refs.canvas.toDataURL("image/jpeg"))
    }
  }
}
</script>